"use strict";
// Unpkg imports
const Web3Modal = window.Web3Modal.default;
const WalletConnectProvider = window.WalletConnectProvider.default;
const storage = window.localStorage;
// const EvmChains = window.EvmChains;

// Web3modal instance
let web3Modal;
// Chosen wallet provider given by the dialog window
let provider;
// Address of the selected account
let selectedAccount;

let web3;
let checkWeb3;
/**
 * Setup the orchestra
 */
function init() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "4052fbef0f3b4be59b96655531123c30",
      },
    },
  };

  web3Modal = new Web3Modal({
    cacheProvider: false, // optional
    providerOptions, // required
  });
}

async function fetchAccountData() {
  //chainId-1.主链|4.测试
  const chainId = await web3.eth.getChainId();
  const accounts = await web3.eth.getAccounts();
  console.log("---accounts", accounts);
  if (accounts.length == 0) {
    onDisconnect(true);
  } else {
    selectedAccount = accounts[0];
    if (selectedAccount.length > 10) {
      $("#walletLabel").html(
        selectedAccount.substring(0, 6) +
          "..." +
          selectedAccount.substring(selectedAccount.length - 4)
      );
    } else {
      $("#walletLabel").html(selectedAccount);
    }
    $("#walletBtn").css("text-transform", "none");
    $("#walletBtn").css("display", "none");
    $("#walletLabel").css("display", "block");
    $("#connected").css("display", "flex");

    storage.isConnect = 1;
    storage.walletAddress = selectedAccount;
    $("#walletBtn").unbind();
    $("#walletBtn").on("click", onDisconnect);
    $("#walletIcon").removeClass("fa-wallet");
    $("#walletIcon").addClass("fa-sign-out-alt");
    // Go through all accounts and get their ETH balance
    const rowResolvers = accounts.map(async (address) => {
      const balance = await web3.eth.getBalance(address);
      const ethBalance = web3.utils.fromWei(balance, "ether");
      //账户余额
      const humanFriendlyBalance = parseFloat(ethBalance).toFixed(4);
    });

    await Promise.all(rowResolvers);
  }
}

async function setPorvider(callModal = false) {
  try {
    if (callModal) {
      provider = await web3Modal.connect();
    } else {
      provider =
        Web3.givenProvider ||
        Web3.providers.WebsocketProvider("ws://localhost:8545");
    }
    await setWeb3();
  } catch (e) {
    console.log("Could not get a wallet connection", e);
    return;
  }
  provider.on("accountsChanged", (accounts) => {
    console.log("accountsChanged");
    fetchAccountData();
  });
  provider.on("chainChanged", (chainId) => {
    console.log("chainChanged");
    fetchAccountData();
  });
  await fetchAccountData();
}

async function setWeb3() {
  web3 = new Web3(provider);
}

async function autoConnect() {
  setPorvider();
  fetchAccountData();
}

async function modalConnect() {
  setPorvider(true);
}

async function checkAccount() {
  console.log("checkAccount checkWeb3 givenProvider-->", Web3.givenProvider);
  checkWeb3 = new Web3(Web3.givenProvider);
  // alert("checkWeb3-->" + await checkWeb3.eth.getAccounts());
  console.log(checkWeb3.eth);
  console.log("checkAccount checkWeb3-->", checkWeb3.eth);
  try {
    const accounts = await checkWeb3.eth.getAccounts();
    console.log("checkAccount checkWeb3-->", accounts);
    if (accounts.length == 0) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function onDisconnect(callModal = false) {
  provider = null;
  selectedAccount = null;
  storage.isConnect = 0;
  storage.walletAddress = "";
  $("#walletLabel").css("display", "none");
  $("#connected").css("display", "none");
  $("#walletBtn").css("display", "block");
  $("#walletBtn").css("text-transform", "uppercase");
  $("#walletBtn").unbind();
  init();
  $("#walletBtn").on("click", modalConnect);
  $("#walletIcon").removeClass("fa-sign-out-alt");
  $("#walletIcon").addClass("fa-wallet");
  setPorvider(true);
}

async function loadDelay() {
  let isConnect = storage.isConnect;
  if (isConnect == 1) {
    //缓存为连接,实际为连接
    autoConnect();
     var hasAccount = await checkAccount();
    console.log("hasAccount", hasAccount);
    if (hasAccount){
      $("#connected").css("display", "flex");
    } 
  } else {
    $("#walletBtn").on("click", modalConnect);
    $("#connected").css("display", "none");
    init()
    setPorvider(true);
  }
}

$(window).on("load", async function () {
  setTimeout(() => {
    loadDelay();
  }, 500);
});
